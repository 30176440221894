import React from 'react';
import classes from './Footer.module.css';

const Footer = () => {

    return(
        <div className={classes.Footer}>
            trezza.dev 2021 
        </div>
        )
}

export default Footer; 