import React from 'react';
import classes from './Skills.module.css';
import { NavLink } from 'react-router-dom';

const Skills = () => {

    return(
        <div className={classes.Skills}>
            <div className={classes.Header}>
            <h1>SKILLS</h1>
            </div>
            <div className={classes.SkillsSummary}>
                {/* I love javascript and front end development, especially with React. I also love using Node.js to make computers talk to each other. */}
                {/* No, I don't include <em style={{ fontStyle: 'italic' }}>these</em> interests in my Tinder profile. 
                Having spent the past ten years of my career working in product development and project management, 
                I know how to keep my projects organized, communicate effectively with team members and clients, manage customer expectations,
                and meet deadlines.  */}
            </div>
            <div className={classes.SkillsContainer}>
            <div className={classes.SkillBox}>
                <h3>Front End</h3>
                <ul>
                    <li>JAVASCRIPT</li>
                    <li>REACT</li>
                    <li>HTML</li>
                    <li>CSS</li>
                </ul>
            </div>
            <div className={classes.SkillBox}>
                <h3>Back End</h3>
                <ul>
                    <li>NODE / Express</li>
                    <li>PYTHON </li>
                    <li>MONGODB</li>
                    <li>MYSQL</li>
                </ul>
            </div>
            <div className={classes.SkillBox}>
                <h3>Design</h3>
                <ul>
                    <li>PHOTOSHOP</li>
                    <li>ILLUSTRATOR</li>
                    <li>INDESIGN</li>
                    <li>FIGMA</li>
                </ul>
            </div>
            </div>
        <div className={classes.Action}>
            <NavLink to='/portfolio' exact>
            <div className={classes.PortfolioBtn}>
                <h1>View Portfolio</h1>
            </div>
            </NavLink>
        </div>
        </div>
        )
}

export default Skills; 