import React from 'react';
import classes from './About.module.css';

const About = () => {

    return(
        <div className={classes.About}>
            <h1>
                Hi, I'm James.
            </h1>
            <h2>I'm a fullstack web developer</h2>
            <div className={classes.Introduction}>
                I love to code and build things. When I'm not coding I'm probably riding my mountain bike or sampling old soul songs and making music. 
            </div>
        </div>)
}

export default About; 