import './App.css';
import Layout from "./Containers/MainLayout/MainLayout";

function App() {
  return (
    <div className="App">
      <Layout />
      
    </div>
  );
}

export default App;
