import React from 'react'; 
import classes from './Home.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';

const Home = () => {

    return(
        <div className={classes.Home}>
            
            <div className={classes.Header}>
            <h1>trezza.dev</h1>
            <div className={classes.SubHead}>
                <div>FRONT END DEVELOPER
                </div>
                <div>BACK END DEVELOPER</div>
                <div>PROGRAMMER</div>

            </div>
            <div className={classes.SocialMediaLinks}>
                <a href="https://github.com/jt2tight"><FontAwesomeIcon className={classes.Icon} icon={faGithub} /></a>
                <a href="https://www.instagram.com/jt2tight/"><FontAwesomeIcon className={classes.Icon} icon={faInstagram} /></a>
            </div>
            
            </div>
            
        </div>
        )
};

export default Home; 