import React from 'react';
import classes from './Contact.module.css';

const Contact = () => (
    <div className={classes.Contact}>
        <h1>Get in touch</h1>
        <h3><a href="mailto:jtrezza@protonmail.com">jtrezza@protonmail.com</a></h3>
    </div>
)

export default Contact; 