import React, { Component } from 'react';
import classes from './Portfolio.module.css';
import PortfolioItem from '../../Components/PortfolioItem/PortfolioItem';
import CK1 from '../../images/CK1.jpg';
import CW1 from '../../images/CW1.jpg';
import Pusher1 from '../../images/Pusher1.jpg';
import TrezzaDevV1 from '../../images/trezza-devV1.png';
import SC_large from '../../images/SC_lg.png';
import Spinner from '../../Components/Spinner/Spinner';

class Portfolio extends Component {

    state = {
        loading: true
    }
    


    componentDidMount(){
        setTimeout(()=> {
            this.setState({
                loading: false
            })
        }, 1000)
    }
    

    render(){

    window.scrollTo(0,0)

    let portfolioItems = <Spinner />

    if(!this.state.loading){
        portfolioItems = (
            <div className={classes.Items}>
                <PortfolioItem 
                    title="Santa Cruz Bike Builder"
                    image={SC_large}
                    detail1='React'
                    detail2='Node.js / MongoDb'
                    detail3='Bike Builder / E-commerce'
                    gitLink="https://github.com/jt2tight/santa-cruz-backend"
                    siteLink="https://santa-cruz-bike-builder.web.app/"

                />
                <PortfolioItem 
                    title="TREZZA.DEV v1"
                    image={TrezzaDevV1}
                    detail1='React'
                    detail2='Version 1 of this site'
                    detail3='Experiment with animation'
                    gitLink=""
                    siteLink="https://trezza-dev-main-site.web.app"

                />
                <PortfolioItem 
                    title='CRYPT KEEPER'
                    image={CK1}
                    detail1='Node.js / Express'
                    detail2='MongoDb'
                    detail3='Crypto Portfolio Tracker'
                    gitLink="https://github.com/jt2tight/crypt-keeper"
                    siteLink="https://crypt-keeper-trezza-dev.herokuapp.com/"

                />
                <PortfolioItem
                title='COLDWATER MOUNTAIN'
                image={CW1}
                detail1='Node.js / Express'
                detail2='MongoDb'
                detail3='Brochure / E-commerce'
                gitLink="https://github.com/jt2tight/coldwater-mountain"
                siteLink="https://coldwater-mountain.herokuapp.com/"
                />
                <PortfolioItem 
                title='PUSHER: INTERGALACTIC'
                image={Pusher1}
                detail1='Vanilla Javascript'
                detail2='Text-based Game'
                detail3='My first js project'

                />
                <PortfolioItem />
            </div>
        )
    }

    return(
        <div className={classes.Portfolio}>
            <div className={classes.InnerContainer}>

            
            <div className={classes.Header}>
            <h1>PORTFOLIO</h1>
            </div>
            {portfolioItems}

            </div>

        </div>
        )
    }
}

export default Portfolio; 