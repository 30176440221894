import React, { Component } from 'react';
import classes from './PortfolioItem.module.css';

class PortfolioItem extends Component {

    state = {
        mouseOver: false
    }

    render(){

    let projectInfoStyle = classes.ProjectInfoOff;

    if(this.state.mouseOver){
        projectInfoStyle = classes.ProjectInfo
    }

    return(
        <div onMouseOver={()=> this.setState({mouseOver: true})} onMouseOut={()=> this.setState({mouseOver: false})} className={classes.PortfolioItem} style={{ backgroundImage : `url(${this.props.image})`}}>
            <div className={projectInfoStyle}>
            <div className={classes.Header}>
                <h2>{this.props.title}</h2>
            </div>
            <div className={classes.Details}>
                <p>Made with</p>
                <ul>
                    <li>{this.props.detail1}</li>
                    <li>{this.props.detail2}</li>
                    <li>{this.props.detail3}</li>
                </ul>
            </div>
            <div className={classes.Action}>
            <p><a href={this.props.siteLink}>View Project</a></p>
            <p><a href={this.props.gitLink}>View Code</a></p>
            </div>
            </div>

        </div>)
}};

export default PortfolioItem; 