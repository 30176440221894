import React, { Component } from 'react';
import classes from './MobileNav.module.css';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';


class MobileNav extends Component {

    state = {
        menuOpen: false
    }

    render(){
    
    let menuClass = classes.NavClosed;

    if(this.state.menuOpen){
        menuClass = classes.NavItems;
    }
    
    return(
        <div className={classes.MobileNav}>
            <div className={classes.NavAction}>
            <GiHamburgerMenu className={classes.Burger} onClick={() => this.setState(prevState =>({menuOpen: !prevState.menuOpen}))}/>
            </div>
            
            <div className={menuClass} onClick={() => this.setState({menuOpen: false})}>
                <ul >
                    <li><NavLink to="/" exact >HOME</NavLink></li>
                    <li><NavLink to="/portfolio" exact >PORTFOLIO</NavLink></li>
                    <li><NavLink to="/contact" exact >CONTACT</NavLink></li>
                </ul>
            </div>
        </div>

        )
    }

}

export default MobileNav; 