import React from 'react';
import classes from './NavBar.module.css';
import { NavLink } from 'react-router-dom';


const NavBar = () => {

    return(
        <div className={classes.NavBar}>
            <div className={classes.Desktop}>
            <ul>
            <li><NavLink to="/">HOME</NavLink></li>
                <li><NavLink to="/portfolio">PORTFOLIO</NavLink></li>
                <li><NavLink to="/contact">CONTACT</NavLink></li>
            </ul>
            </div>
            
            
            
        </div>
            )
};

export default NavBar; 