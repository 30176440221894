import React from 'react';
import classes from './MainLayout.module.css';
import NavBar from '../../Components/NavBar/NavBar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Landing from '../Landing/Landing';
import Footer from '../../Components/Footer/Footer';
import MobileNav from '../../Components/NavBar/MobileNav/MobileNav';
import Portfolio from '../Portfolio/Portfolio';
import Contact from '../../Components/Contact/Contact';

const Layout = () => {
    window.scrollTo(0,0)

    return(
        <div className={classes.Layout}>
            <Router>
            <NavBar />
            <MobileNav />
            <Switch>
                <Route component={Landing} path='/' exact />
                <Route component={Portfolio} path="/portfolio" exact />
                <Route component={Contact} path="/contact" exact />
            </Switch>
            </Router>
            <Footer />
        </div>
        )
};

export default Layout; 