import React from 'react';
import classes from './Landing.module.css';
import Home from '../Home/Home';
import About from '../About/About';
import Skills from '../Skills/Skills';

const Landing = () => (
    <div className={classes.Landing}>
        <Home />
        <About />
        <Skills />
    </div>
)

export default Landing; 